import { TelemetryEvent } from "@headbot/library";
import * as React from "react";
import { useState } from "react";
import styled, { StyledComponent } from "styled-components";
import { telemetry } from "../../../services/Telemetry/Telemetry";
import { ExternalLinkIcon } from "../../atoms/Icon/ExternalLinkIcon";
import { Color } from "../../layout/GlobalStyles/Color";

interface IProps {
    readonly href: string;
    readonly target?: "_blank" | "_self" | "_parent" | "_top" | "framename";
    readonly styledOverride?: StyledComponent<"a", any, {}, never>;
    readonly hideIconOverride?: boolean;
}

const defaultStyledLink = styled.a`
    color: ${Color.Primary100};
    cursor: pointer;
    text-decoration: none;
    display: inline;
    font-weight: 700;

    :hover {
        color: ${Color.Primary110};
    }
`;

export const TelemetryLink: React.FC<React.PropsWithChildren<IProps>> = ({
    href,
    children,
    target = "_self",
    styledOverride,
    hideIconOverride = false,
}) => {
    const shouldShowIcon = target === "_blank" && !hideIconOverride;
    const StyledLink = styledOverride ?? defaultStyledLink;

    const [hasLoggedTelemetry, setHasLoggedTelemetry] = useState(false);
    const onClick = async (ev: React.MouseEvent) => {
        if (target !== "_blank" && hasLoggedTelemetry === false) {
            await telemetry.Log(TelemetryEvent.InternalLinkClicked, href);
            ev.stopPropagation();
            setHasLoggedTelemetry(true);
            ev.target.dispatchEvent(new MouseEvent("click"));
        } else if (target === "_blank") {
            telemetry.Log(TelemetryEvent.ExternalLinkClicked, href);
        }
    };
    return (
        <StyledLink onClick={onClick} href={href} target={target}>
            {children}
            {shouldShowIcon && (
                <>
                    {" "}
                    <ExternalLinkIcon />
                </>
            )}
        </StyledLink>
    );
};
